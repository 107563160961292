<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    getMerchant,
    addMerchant,
    editMerchant,
    deleteMerchant,
    updateKyzt
  } from "@/api/pay/merchant.js"

  /**
   * 支付管理--支付
   */
  export default {
    components: {
      Layout,
      PageHeader,
    },
    data() {
      return {
        title: "商户管理 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "商户管理",
            active: true
          }
        ],
        tableList: [],
        showmodal: false,
        dialogTitle: "",
        module: "SHGL",
        addForm: {},
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          shmc: "",
        }
      };
    },
    methods: {
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      getList() {
        getMerchant(this.pageData).then(res => {
          if (res.status) {
            if (res.status) {
              this.tableList = res.data
              this.pageData.total = res.total

            }
          }
        })
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加商户信息"
        this.addForm = {}
        this.addForm.kyzt = 1
        this.getBm(this.module, "sh").then(e => {
          this.addForm.shbm = e
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        console.log(this.addForm.kyzt)
        this.dialogTitle = "修改商户信息"
      },
      // 添加商户
      addMerchant() {
        let formDate = this.addForm
        addMerchant(formDate).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      // 修改商户
      editMerchant() {
        let formDate = this.addForm
        formDate.sid = this.addForm.sid
        editMerchant(formDate).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.shmc}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteMerchant(obj.sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },

      // 改变启用状态
      changeKyzt(obj, val) {
        let f = {
          sid: obj,
          kyzt: val ? "1" : "0"
        }
        updateKyzt(f).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '数据修改成功'
            });
          }
        })
      },
      submit() {
        if (this.addForm.sid) {
          this.editMerchant()
        } else {
          this.addMerchant()
        }
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.addForm = {}
        this.showmodal = false;
        this.getList()
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
    },
    mounted() {
      this.getList()
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <input placeholder="商户名称" v-model="pageData.shmc" class="h30 form-control border-blue mr-2"
                  maxlength="50" style="width: 156px;" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加商户</b-button>
              </div>
              <!-- <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: '商户信息导出', type: 'xlsx', module: ''})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: '商户信息导出', type:'dbf', module: ''})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div> -->
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th>商户编码</th>
                    <th>商户名称</th>
                    <!--  <th style="width: 5%;">开启微信 </th>
                    <th style="width: 12%;">开启易宝 </th>
                    <th style="width: 6%;">微信子商户号 </th> -->
                    <th style="width: 5%;">可用状态</th>
                    <th style="width: 13%;">创建时间</th>
                    <th style="width: 6%;" class="text-center">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in tableList" :key="index">
                    <td> {{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{item.shbm}} </td>
                    <td>{{item.shmc}}</td>
                    <!-- <td>{{item.wxpay}}</td>
                    <td>{{item.yibaopay}}</td>
                    <td>{{item.wx_sub_mchid}}</td> -->
                    <td>
                      <b-form-checkbox v-model="item.kyzt" switch class=" switch-check" :value="1" :unchecked-value="0"
                        @change="changeKyzt(item.sid,item.kyzt)">
                      </b-form-checkbox>
                    </td>

                    <td>{{item.createTime}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click='editItem(item)'></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(item)"></i>
                    </td>
                  </tr>


                </tbody>

              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal id="addMerchant" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>商户编码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" placeholder="商户编码，不能重复" maxlength="50" v-model="addForm.shbm"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>商户名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" placeholder="请输入商户名称" v-model="addForm.shmc"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div class="d-flex check-distri mb-3"><label>类别属性：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.lbsx">
                  <option value="资格类">资格类</option>
                  <option value="水平类">水平类</option>
                  <option value="选拔类">选拔类</option>
                  <option value="其他类">其他类</option>
                </select>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否可用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.kyzt"
                    value=1 class="form-check-input"><label for="exampleRadios1" class="form-check-label">可用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.kyzt"
                    value=0 class="form-check-input"><label for="exampleRadio1" class="form-check-label">不可用</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div class="d-flex check-distri mb-3"><label>排序数值：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input id="number" value="1" type="number" name="number" v-model="addForm.pxh" class="w-100 h30"
                  placeholder="序号越小排序越靠前">
                </b-form-input>
    
              </div>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>

    <!-- 弹窗结束 -->
  </Layout>
</template>
