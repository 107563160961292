// 商户

import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  patchRequest
} from "@/libs/axios";
// 商户分页查询
export const getMerchant = params => {
  return getRequest("/yethan/pay/merchant/listPage", params);
};
// 添加
export const addMerchant = params => {
  return postRequest("/yethan/pay/merchant", params);
};
// 修改
export const editMerchant = params => {
  return putRequest("/yethan/pay/merchant", params);
};
// 删除
export function deleteMerchant(sid) {
  return deleteRequest("/yethan/pay/merchant/" + sid);
}

// 更新可用状态
export function updateKyzt(data={}) {
    return patchRequest( '/yethan/pay/merchant/kyzt', data);
}